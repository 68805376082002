<template>
  <div class="introducer-page introducer-form">
    <!-- introducer Details -->
    <vs-row>
      <vx-card class="accordin-card mt-5">
        <vs-collapse accordion class="introducer-details">
          <vs-collapse-item ref="introducerDetails" :open="true">
            <div slot="header">
              <h3 class="text-left font-normal">Introducer details <span class="mid-blue text-lg mb-0">*</span></h3>
            </div>
            <vs-row>
              <vs-col vs-w="4" vs-xs="12">
                <div>
                  <label class="w-full">Introducer Name<span class="mid-blue text-lg mb-0">*</span></label>
                  <vs-input v-model="formData.name" v-validate="'required'" class="w-full" name="name" size="large" />
                  <span v-show="errors.has('name')" class="text-danger text-sm">{{ errors.first("name") }}</span>
                </div>
              </vs-col>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </vx-card>
    </vs-row>

    <vs-row>
      <vx-card class="accordin-card mt-5">
        <vs-collapse accordion>
          <vs-collapse-item ref="onboardingProfile" :open="false">
            <div slot="header">
              <h3 class="text-left font-normal">Onboarding</h3>
            </div>
            <vs-row>
              <vs-col vs-w="4" vs-xs="12">
                <div class="input-grp">
                  <label for="onboardingProfile" class="w-full text-xm font-normal">Onboarding profile</label>
                  <vs-select
                    v-model="formData.onboardingProfileId"
                    id="onboardingProfile"
                    name="onboardingProfileId"
                    class="w-12/12"
                    data-vv-as="Settlement Account"
                    data-vv-validate-on="blur"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item._id"
                      :text="item.name"
                      v-for="(item, index) in onboaridngProfiles"
                    />
                  </vs-select>
                  <span class="text-danger text-sm" v-show="errors.has('onboardingProfile')">{{ errors.first("onboardingProfile") }}</span>
                </div>
              </vs-col>
            </vs-row>
            <h6 class="mt-12">Default ANZSIC code setting</h6>
            <hr class="mt-2">
            <vs-row class="mt-8">
              <vs-col vs-w="4" vs-xs="12">
                <div class="input-grp">
                  <label class="w-full">Industry</label>
                  <vs-select v-model="industry" id="industry" name="industry" class="w-12/12" @change="selectIndustryAndMountCategory($event)">
                    <a v-if="industry" @click="unselectIndustry">Unselect</a>
                    <vs-select-item :key="index" :value="item.name" :text="item.name" v-for="(item, index) in industries" />
                  </vs-select>
                </div>
              </vs-col>
              <vs-col vs-w="4" vs-xs="12">
              <div class="input-grp">
                <label class="w-full">Category</label>
                <vs-select v-model="category" id="category" name="category" class="w-12/12" v-validate="industry ? 'required': ''" data-vv-as="category">
                  <vs-select-item :key="index" :text="item.name" :value="item.name" v-for="(item, index) in categories" />
                </vs-select>
              </div>
              <span class="text-danger text-sm" v-show="errors.has('category')">{{ errors.first("category") }}</span>
              </vs-col>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </vx-card>
    </vs-row>

    <!-- Payment Options -->
    <vs-row>
      <vx-card class="accordin-card mt-5">
        <vs-collapse accordion>
          <vs-collapse-item ref="paymentOptions" :open="true">
            <div slot="header">
              <h3 class="text-left card-title font-normal">Payment Options <span class="mid-blue text-lg mb-0">*</span></h3>
            </div>
            <p>Select the payment options you’d like to enable for this introducer.</p>
            <vs-row class="mt-8">
              <vs-col class="pr-6" vs-w="3" vs-xs="12">
                <vs-checkbox v-model="paynow">Pay Now</vs-checkbox>
              </vs-col>
              <vs-col class="pr-6" vs-w="3" vs-xs="12">
                <vs-checkbox v-model="paylater">Pay Later</vs-checkbox>
              </vs-col>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </vx-card>
    </vs-row>

    <!-- Paynow payment methods -->
    <vs-row v-if="paynow">
      <vx-card class="accordin-card mt-5">
        <vs-collapse accordion>
          <vs-collapse-item ref="payNowPaymentMethods" :open="true">
            <div slot="header">
              <h3 class="text-left card-title font-normal">
                Pay Now payment methods
                <span class="mid-blue text-lg mb-0">*</span>
              </h3>
            </div>
            <span>Select the Pay Now payment methods you’d like to offer this merchant.</span>
            <vs-row>
              <vs-col class="pr-6" vs-w="4" vs-xs="12">
                <vs-row class="m-3 mb-10">
                  <vs-select placeholder="Select payment methods" multiple autocomplete class="selectExample" v-model="selectedCards" @change="checkSelected">
                    <div class="selectAllDiv">
                      <a class="dropdownSelectAllButton" @click="selectAllCards">{{ !allCardsSelected ? `Select all` : `Unselect all` }}</a>
                    </div>
                    <vs-select-item :key="index" :value="card" :text="card.title" v-for="(card, index) in cardsList" />
                  </vs-select>
                </vs-row>
              </vs-col>
            </vs-row>

            <vs-row class="intro-cards-container">
              <vs-card class="intro-cards" v-for="(card, indextr) in selectedCards" :key="indextr">
                <div slot="header">
                  <vs-row class="flex iitems-center justify-between">
                    <div class="flex items-center">
                      <S3ImageGenerator :document="card.icon" :is-base64="false" :custom-class="'cardImage'" :key="card.icon" />
                      <h6 class="font-normal text-xl ml-2">{{ card.title }}</h6>
                    </div>
                    <div class="element">
                      <a @click="closeCard(indextr)"><x-icon size="1.5x" class="custom-class"></x-icon></a>
                    </div>
                  </vs-row>
                </div>
                <div>
                  <vs-row>
                    <span>MSF <span class="mid-blue text-lg mb-0">*</span></span>
                  </vs-row>
                  <vs-row>
                    <vs-select placeholder="Select MSF rate" class="selectExample" v-model="card.msf" @change="checkMsf(indextr)">
                      <vs-select-item :key="index" :value="msfRate.msf" :text="msfRate.displayMsf" v-for="(msfRate, index) in msfRates" />
                    </vs-select>
                    <span class="text-danger text-sm" v-show="errors.has(`msf[${indextr}]`)">{{ errors.first(`msf[${indextr}]`) }}</span>
                  </vs-row>
                </div>
                <div>
                  <vs-row>
                    <span>Surcharge to customer<span class="mid-blue text-lg mb-0">*</span></span>
                  </vs-row>
                  <vs-row>
                    <money
                      id="surcharge"
                      v-bind="money2"
                      v-model.lazy="card.surcharge"
                      :name="`surcharge[${indextr}]`"
                      data-vv-as="surcharge"
                      v-validate="
                        `required|decimal|max_value:${card.msf}`
                      "
                      @keydown.native="$event.key === '-' ? $event.preventDefault() : null"
                    ></money>
                    <span class="text-danger text-sm" v-show="errors.has(`surcharge[${indextr}]`)">{{ errors.first(`surcharge[${indextr}]`) }}</span>
                  </vs-row>
                </div>
              </vs-card>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </vx-card>
    </vs-row>

    <!-- paylater products starts here -->

    <vs-row v-if="paylater" class="mt-5">
      <vx-card class="accordin-card">
        <vs-collapse accordion>
          <vs-collapse-item ref="paylaterProducts" :open="true">
            <div slot="header">
              <h3 class="text-left card-title font-normal">Pay Later products <span class="mid-blue text-lg mb-0">*</span></h3>
            </div>
            <p class="mb-4">Enable the Pay Later products available to merchants onboarding through this introducer.</p>
            <!-- dropdown -->
            <vs-row>
              <vs-col class="pr-6" vs-w="4" vs-xs="12">
                <vs-row class="m-3 mb-10">
                  <vs-select placeholder="Select products" multiple autocomplete class="selectExample" v-model="selectedProducts" @change="checkSelectedProducts">
                    <div class="selectAllDiv">
                      <a class="dropdownSelectAllButton" @click="selectAllProducts">{{ !allProductsSelected ? `Select all` : `Unselect all` }}</a>
                    </div>
                    <vs-select-item :key="index" :value="paylaterProduct" :text="paylaterProduct.productName" v-for="(paylaterProduct, index) in paylaterProductsList" />
                  </vs-select>
                </vs-row>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col>
                <div class="plans-grid">
                  <div v-for="(plan, index) in selectedProducts" :key="index" class="plans-item">
                    <div class="plan-items__header w-full flex justify-between">
                      <div>
                        <h4 class="mb-0 font-normal text-xl">{{ plan.productName }}</h4>
                        <p class="py-2">{{ plan.feeModel == "MSF_INCLUDED" ? `MSF to merchant (MSF-M)` : `MSF to customer (MSF-C)` }}</p>
                        <p>Plan ID:&nbsp; {{ plan.customPlanId ? plan.customPlanId : plan.planId }}</p>
                      </div>
                      <x-icon class="plan-item__close cursor-pointer" size="14" @click="closeProduct(index)"></x-icon>
                    </div>
                    <div class="plan-items-content">
                      <label class="w-full" for="msfEdit">MSF <span class="mid-blue text-lg mb-0">*</span></label>
                      <money
                        id="surcharge"
                        v-bind="money2"
                        v-model.lazy="plan.msf"
                        :name="`msf[${index}]`"
                        data-vv-as="msf"
                        v-validate="`required|decimal`"
                        placeholder="Enter MSF"
                        class="w-full mb-5"
                        @keydown.native="$event.key === '-' ? $event.preventDefault() : null"
                      ></money>
                      <vs-button v-round class="w-full only-border-btn" @click="openPlanConfigurationPopup(plan, index)">View Plan Configuration</vs-button>
                    </div>
                  </div>
                </div>
              </vs-col>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </vx-card>
    </vs-row>

    <!-- popup starts-->
    <vs-popup id="holamundo" class="config-popup paymentSummaryPopup" :title="`${activePlan.productName} configuration`" :active.sync="popupActivo" @close="closePopup">
      <div class="w-full" v-if="activePlan.productType == 'B2C_BNPL'">
        <vs-col vs-w="12">
          <vs-row class="flex justify-between pay-later-card lg:p-4 md:p-3 p-2 items-start gray">
            <div class="flex flex-col justify-center items-center">
              <img :src="update" alt="update" />
              <span class="dark-blue text-xs mt-2"> Every</span>
              <span class="dark-blue text-xs">{{ `${this.everyNumber} ${this.weekOrMonthOrDay}` }}</span>
            </div>
            <div class="flex flex-col justify-center items-center">
              <img :src="playCircle" alt="playcircle" />
              <span class="dark-blue text-xs mt-2">Starts on</span>
              <span class="dark-blue text-xs">acceptance date</span>
            </div>
            <div class="flex flex-col justify-center items-center">
              <img :src="stopCircle" alt="stopcircle" />
              <span class="dark-blue text-xs mt-2"> Ends after</span>
              <span class="dark-blue text-xs">{{ `${this.activePlan.noOfPayments} payments` }}</span>
            </div>
          </vs-row>

          <vs-table :data="paymentSchedules" class="payment-schedule-table mt-3">
            <template slot="thead">
              <vs-th class="text-base"> Payment date</vs-th>
              <vs-th class="text-base"> Amount</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].value">
                  {{ formatScheduleDate(data[indextr].value) == formatScheduleDate(new Date()) ? `Today` : formatScheduleDate(data[indextr].value) }}
                </vs-td>
                <vs-td class="sec-row-payment" :data="data[indextr].label">
                  {{ data[indextr].label !== "0.00" ? `$ ${data[indextr].label}` : "-" }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="vs-table--content">
          <vs-row class="flex justify-between">
            <span class="text-lg dark-blue">Customer pays</span>
            <span class="text-lg amount">{{ this.totalCustomerPayable ? `$ ${addZeroes(this.totalCustomerPayable)}` : "-" }}</span>
          </vs-row>
          <vs-row class="mt-4">
            <span class="text-lg dark-blue">Merchant receives</span>
            <span class="text-lg amount">{{ this.merchantAmount ? `$ ${addZeroes(this.merchantAmount)}` : "-" }}</span>
          </vs-row>
          </div>
        </vs-col>
      </div>

      <div class="w-full" v-if="['B2C_BNPO', 'B2C_BNPO_NC'].includes(activePlan.productType)">
        <vs-col vs-w="12">
          <div class="gray mb-5 items-center flex">
            <img :src="calenderImage" alt="calenderImage" />
            <p class="dark-blue text-base font-normal ml-4">Proceeds will be deducted on the {{ activePlan.initialPeriod }} days from the acceptance date</p>
          </div>
          <div class="b2c">
          <vs-row class="flex justify-between">
            <span class="text-lg dark-blue">Customer pays</span>
            <span class="text-lg amount">{{ this.totalCustomerPayable ? `$ ${addZeroes(this.totalCustomerPayable)}` : "-" }}</span>
          </vs-row>
          <vs-row class="mt-4">
            <span class="text-lg dark-blue">Merchant receives</span>
            <span class="text-lg amount">{{ this.merchantAmount ? `$ ${addZeroes(this.merchantAmount)}` : "-" }}</span>
          </vs-row>
          </div>
        </vs-col>
      </div>
    </vs-popup>
    <!-- popup ends -->

    <div class="mt-5" v-if="introducerId && formData.userType == 'apiUser'">
      <vs-card>
        <h3 class="font-normal text-lg mb-4">Third Party Token</h3>
        <template>
          <vs-row>
            <vs-col vs-w="8" vs-xs="12">
              <div><vs-input v-model="thirdPartyAcessToken" class="w-full tokn" disabled label-placeholder="Third Party Token" /></div>
            </vs-col>
            <span class="pt-5 pl-3">
              <vs-tooltip text="Copy to clipboard">
                <vs-icon class="clipIcon" icon="content_copy" size="medium" @click="copyThirdPartyTokenToClipboard" />
                <span>&nbsp;</span>
              </vs-tooltip>
            </span>
          </vs-row>
        </template>
      </vs-card>
    </div>

    <pop-up :errorsList="errors" form-name="UserForm"></pop-up>

    <!-- paylater product ends here -->
    <div class="flex flex-wrap mt-10 items-center">
      <vs-button v-round class="ml-auto mr-5" size="large" @click="submitIntroducer">{{ !introducerId ? `Create Introducer` : `Update Introducer` }}</vs-button>
      <u><a size="large" @click="$router.go(-1)">Cancel</a></u>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PopUp from "@/views/components/ValidationPopup.vue";
import { XIcon } from "vue-feather-icons";
import moment from "moment";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";
import { Money } from "v-money";
import industries from "@/views/resources/ANZSIC/industries";
import categories from "@/views/resources/ANZSIC/categories";

const update = require("@/assets/images/update.png");
const playCircle = require("@/assets/images/play-circle.png");
const stopCircle = require("@/assets/images/stop-circle.png");
const calenderImage = require("@/assets/images/calender.png");

export default {
  components: { XIcon, PopUp, S3ImageGenerator, Money },
  data() {
    return {
      thirdPartyAcessToken: "",
      introducerId: "",
      formData: {
        name: "",
        requestType: [],
        requestOptions: [],
        payLaterPlan: "",
        cardOptions: "",
        onboardingProfileId: "",
      },
      paynow: false,
      paylater: false,
      cardsList: [],
      selectedCards: [],
      allCardsSelected: false,
      paylaterProductsList: [],
      selectedProducts: [],
      onboaridngProfiles: [],
      allProductsSelected: false,
      uploadedUrl: process.env.VUE_APP_API_URL + "uploads/icons/",
      amount: "1000",
      weekOrMonthOrDay: "",
      activePlan: "",
      everyNumber: "1",
      popupActivo: false,
      totalCustomerPayable: "",
      merchantAmount: "",
      paymentSchedules: [],
      update: update,
      playCircle: playCircle,
      stopCircle: stopCircle,
      calenderImage: calenderImage,
      money2: {
        precision: 2,
        suffix: " %",
      },
      industries: [],
      categories: [],
      industry: "",
      category: "",
      initialLoad: true,
      msfRates: [],
    };
  },

  methods: {
    ...mapActions("card", ["fetchAllCards", "deletePartnerCard"]),
    ...mapActions("onboardingAdmin", ["calculateOnPayLater"]),
    ...mapActions("paymentPlans", ["fetchAllPlans"]),
    ...mapActions("onboardingProfile", ["fetchOnboardingProfileForList"]),
    ...mapActions("introducer", ["fetchIntroducerDetailsById", "saveIntroducer", "updateIntroducer"]),
    ...mapActions("admin", ["checkLogin"]),
    ...mapActions("setting", ["fetchSetting"]),

    copyThirdPartyTokenToClipboard() {
      navigator.clipboard.writeText(this.thirdPartyAcessToken).then(() => {
        this.$vs.notify({
          title: "Copy to Clipboard",
          text: "Copied text to clipboard",
          color: "success",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-check-circle",
        });
      });
    },

    //* functions for plan configuration popup start here
    async openPlanConfigurationPopup(plan, index) {
      this.activePlan = plan;
      this.popupActivo = true;

      await this.calculateOnPayLater({ planId: plan._id, msf: this.selectedProducts[index].msf }).then((result) => {
        const total = result.data.data.find(item => item.type == "Total");
        const disbursement = result.data.data.find(item => item.type == "Disbursement");
        const schedule = result.data.data.filter(item => item.type == "Payment");

        if (this.activePlan.productType == "B2C_BNPL") {
          this.payLaterSchedule = schedule.map((item) => {
            return {
              label: item.amount,
              value: new Date(item.date),
            };
          });
          this.paymentSchedules = this.payLaterSchedule;
          this.totalNumberOfPayments = this.payLaterSchedule.length;
        }

        this.totalCustomerPayable = total.amount;
        this.merchantAmount = disbursement.amount;
      });

      //for finding week month or day
      if (this.activePlan.paymentFrequency) {
        switch (this.activePlan.paymentFrequency) {
          case "DAILY" :
            this.weekOrMonthOrDay = "day";
            break;

          case "WEEKLY" :
            this.weekOrMonthOrDay = "week";
            break;

          case "FORTNIGHTLY" :
            this.everyNumber = "2";
            this.weekOrMonthOrDay = "weeks";
            break;

          case "MONTHLY" :
            this.weekOrMonthOrDay = "month";
            break;

          default:
            this.weekOrMonthOrDay = "day";
            break;
        }
      }
    },
    moment(date) {
      return moment(date).format("D-MMM-YY");
    },

    formatScheduleDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    getWeekDay(date) {
      return moment(date).format("dddd");
    },

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2 });
    },

    closePopup() {
      this.weekOrMonthOrDay = "";
      this.activePlan = {};
      this.paymentSchedules = [];
      this.totalCustomerPayable = "";
      this.merchantAmount = "";
    },

    // for paynow
    async getAllCards() {
      await this.fetchAllCards().then((res) => {
        this.cardsList = res.data.data;
      });

      if (this.introducerId) {
        this.getIntroducerById(this.introducerId);
      }
    },

    closeCard(index) {
      if (index > -1) {
        this.selectedCards.splice(index, 1);
      }
    },

    selectAllCards() {
      this.allCardsSelected = !this.allCardsSelected;
      if (this.allCardsSelected) {
        this.selectedCards = [...this.cardsList];
      } else {
        this.selectedCards = [];
      }
    },

    checkSelected() {
      if (this.selectedCards.length == this.cardsList.length) {
        this.allCardsSelected = true;
      } else {
        this.allCardsSelected = false;
      }
    },

    // for paylater
    async getAllPaylaterProducts() {
      await this.fetchAllPlans('?page=1&limit=1000&sort=desc').then((res) => {
        this.paylaterProductsList = res.data.data.docs;
      });
    },

    closeProduct(index) {
      if (index > -1) {
        this.selectedProducts.splice(index, 1);
      }
    },

    selectAllProducts() {
      this.allProductsSelected = !this.allProductsSelected;
      if (this.allProductsSelected) {
        this.selectedProducts = [...this.paylaterProductsList];
      } else {
        this.selectedProducts = [];
      }
    },

    checkSelectedProducts() {
      if (this.selectedProducts.length == this.paylaterProductsList.length) {
        this.allProductsSelected = true;
      } else {
        this.allProductsSelected = false;
      }
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    async getIntroducerById(id) {
      this.fetchIntroducerDetailsById(id).then((res) => {
        this.formData = res.data.data;
        this.formData.onboardingProfileId = this.formData.onboardingProfileId ? this.formData.onboardingProfileId._id : "";
        this.industry = this.formData.industry || "";
        this.category = this.formData.category || "";

        if (res.data.data.thirdPartyAcessToken) {
          this.thirdPartyAcessToken = res.data.data.thirdPartyAcessToken;
        }

        if (res.data.data.paymentOptions.requestOptions.includes("pay-now")) {
          this.paynow = true;
        }

        if (res.data.data.paymentOptions.requestOptions.includes("pay-later")) {
          this.paylater = true;
        }

        let cards = [...res.data.data.cardOptions];
        cards.map((element) => {
          for (let i = 0; i < this.cardsList.length; i++) {
            if (element._id == this.cardsList[i]._id) {
              this.cardsList[i].msf = parseFloat(element.msf);
              this.cardsList[i].surcharge = parseFloat(element.surcharge);
              this.selectedCards.push(this.cardsList[i]);
            }
          }
        });

        if (res.data.data.paymentOptions.payLaterPlan && res.data.data.paymentOptions.payLaterPlan.length) {
          let paylaterPlan = [...res.data.data.paymentOptions.payLaterPlan];
          paylaterPlan.map((element) => {
            for (let i = 0; i < this.paylaterProductsList.length; i++) {
              if (element.customPlanId == this.paylaterProductsList[i].customPlanId) {
                this.paylaterProductsList[i].displayName = element.displayName;
                this.paylaterProductsList[i].msf = parseFloat(element.msf).toFixed(2);
                this.selectedProducts.push(this.paylaterProductsList[i]);
              }
            }
          });
        }
      });
    },

    // click save button
    async submitIntroducer() {
      // get onboarding profile
      try {
        const valid = await this.$validator.validate();
        if (!valid) {
          return false;
        }

        if (!this.paynow && !this.paylater) {
          return false;
        }

        this.$vs.loading();

        if (this.paynow == true) {
          this.formData.requestType = ["one-off", "recurring"];
          this.formData.requestOptions = ["pay-now"];
        }

        if (this.paylater == true) {
          this.formData.requestOptions = ["pay-later"];
        }

        if (this.paylater == true && this.paynow == true) {
          this.formData.requestOptions = ["pay-now", "pay-later"];
        }

        if (this.paylater == true && this.paynow == false) {
          this.formData.requestType = ["one-off"];
        }

        if (this.selectedProducts.length > 0) {
          this.formData.payLaterPlan = this.selectedProducts.map((el) => {
            return {
              customPlanId: el.customPlanId,
              msf: el.msf,
            };
          });
        }

        if (this.selectedCards.length > 0) {
          this.formData.cardOptions = this.selectedCards;
        }

        this.formData.industry = this.industry;
        this.formData.category = this.category;

        // CREATE NEW INTRODUCER
        if (!this.$route.params.id) {
          this.saveIntroducer(this.formData).then((res) => {
            this.$vs.loading.close();
            this.showMessage("Success", "Introducer has been created successfully.", "success", "icon-check-circle");
            this.$router.push({ name: "introducers-list" });
          }).catch((err) => {
            this.$vs.loading.close();
            this.showMessage("Error", err.data.message, "danger", "icon-times");
          });
        }
        if (this.$route.params.id) {
          this.updateIntroducer({ id: this.$route.params.id, info: this.formData }).then((res) => {
            this.$vs.loading.close();
            this.showMessage("Success", "Introducer has been updated successfully.", "success", "icon-check-circle");
            this.$router.push({ name: "introducers-list" });
          }).catch((err) => {
            this.$vs.loading.close();
            this.showMessage("Error", err.data.message, "danger", "icon-times");
          });
        }
      } catch (err) {
        this.$vs.loading.close();
      }
    },

    async getOnboardingProfile() {
      this.fetchOnboardingProfileForList().then((response) => {
        this.onboaridngProfiles = response.data.data;
      }).catch((err) => {});
    },

    async checkAdminLogin() {
      await this.checkLogin();
    },

    selectIndustryAndMountCategory(event) {
      this.categories = categories.filter((category) => category.industry === event );

      if (!this.initialLoad) {
        this.category = "";
      }

      this.initialLoad = false;
    },

    unselectIndustry() {
      this.industry = "";
    },

    async getMsfSetting() {
      await this.fetchSetting('surcharge')
      .then((res) => {
        this.msfRates = res.data.data.map((element) => {
          return {
            ...element,
            displayMsf: element.msf + " %"
          };
        });
      }).catch((ex) => {
        this.$vs.notify({
          title: "Error",
          text: "Error fetching setting",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
    },

    checkMsf(index) {
      const objIndex = this.msfRates.findIndex((obj) => obj.msf == this.selectedCards[index].msf);
      if (objIndex < 0) {
        this.errors.add({
          "field" : `msf[${index}]`,
          "msg": "Please select valid msf from the list"
        });
      } else {
        if (this.errors.has(`msf[${index}]`)) {
          this.errors.remove(`msf[${index}]`);
        }
      }
      if (this.selectedCards[index].surcharge > this.selectedCards[index].msf) {
        this.errors.add({
          "field": `surcharge[${index}]`,
          "msg": `The surcharge field must be ${this.selectedCards[index].msf} or less`
        });
      } else {
        if (this.errors.has(`surcharge[${index}]`)) {
          this.errors.remove(`surcharge[${index}]`);
        }
      }
    },

    assignDefaultMsf(index) {
      const objIndex = this.msfRates.findIndex((obj) => obj.msf == this.selectedCards[index].msf);
      if (objIndex < 0) {
        this.selectedCards[index].msf = "1.50";
        this.selectedCards[index].surcharge = "1.50";
      }
    }
  },
  watch: {
    "errors.items"(val) {
      if (this.errors.items.length > 0) {

        if (this.$refs.introducerDetails) {
          this.$refs.introducerDetails.maxHeight = "none !important";
        }

        if (this.$refs.introducerAdmin) {
          this.$refs.introducerAdmin.maxHeight = "none !important";
        }
      }
    },

    selectedCards(val) {
      if (this.$refs.payNowPaymentMethods) {
        this.$refs.payNowPaymentMethods.maxHeight = "none !important";
      }
      this.assignDefaultMsf(this.selectedCards.length - 1);
    },

    selectedProducts(val) {
      if (this.$refs.paylaterProducts) {
        this.$refs.paylaterProducts.maxHeight = "none !important";
      }
    },
  },

  async created() {
    this.checkAdminLogin();
    this.industries = industries;
    this.introducerId = this.$route.params.id;
    this.getMsfSetting();
    await this.getAllPaylaterProducts();
    this.getOnboardingProfile();
    this.getAllCards();
  },
};
</script>

